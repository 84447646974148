import React from "react";

import { default as App } from "@carnegie-mellon-robotics-academy/rah_image_classification_prototype";
import ElementContainer from "../../../shared/ElementContainer";

const RahImageClassifier = (props) => {
  const {
    token,
    requirement,
    title,
    title_color,
    completed,
    domain,
    game_settings,
    id,
    user,
  } = props;
  console.log("domain", domain);
  return (
    <ElementContainer
      id={id}
      title={title}
      color={title_color}
      completed={completed}
      requirable={true}
      icon={"bx bxs-joystick icon-medium"}
      user={user}
      token={token}
    >
      <App game={{ token, requirement, domain, game_settings, completed }} />
    </ElementContainer>
  );
};

export default RahImageClassifier;
