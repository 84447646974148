// consumer.js
import { createConsumer } from "@rails/actioncable"

// Keep a global reference to our consumer.
let consumer

export default function getConsumer(jwt) {
	// Only instantiate if we don’t have a consumer yet,
	// or if we need to handle an updated JWT, etc.
	if (!consumer) {
		consumer = createConsumer(`${window.action_url}cable?token=${jwt}`)
	}

	return consumer
}
