import React, { useState } from "react";
import ElementContainer from "../../../shared/ElementContainer";
import Modal from "../../../shared/Modal";
import LoadingSpinner from "../../../shared/LoadingSpinner";

import { FaTimes } from "react-icons/fa";
import { FaSpinner } from "react-icons/fa";

const UnityGameElement = (props) => {
  const {
    title,
    title_color,
    id,
    requirable,
    status,
    token,
    completed,
    url,
    configuration,
    splash_image,
    active_track,
    user,
  } = props;

  const [initialize, setInitialize] = useState(false);
  const [display, setDisplay] = useState(false);
  const [hideSpinner, setHideSpinner] = useState(false);

  const closeDisplay = () => setDisplay(false);

  const cursor = {
    cursor: "pointer",
  };

  const closeButton = {
    borderBottomLeftRadius: 25,
    position: "fixed",
    top: 0,
    right: 0,
  };

  return (
    <ElementContainer
      id={id}
      color={title_color}
      title={title}
      icon={"bx-laptop"}
      requirable={requirable}
      status={status}
      completed={completed}
      user={user}
      token={token}
    >
      <a onClick={() => setInitialize(true)}>
        <div
          onClick={() => setDisplay(true)}
          style={cursor}
          className="btn btn-secondary expand radius drop-shadow"
        >
          <img src={splash_image} />
        </div>
      </a>

      {initialize ? (
        <Modal display={display} modalId={id} closeDisplay={closeDisplay}>
          <div className="btn btn-cs2n-red" style={closeButton}>
            <FaTimes className="icon-medium" /> Close Activity
          </div>
          {hideSpinner ? null : <LoadingSpinner />}
          <iframe
            id="unity_game_element"
            src={`${url}?id=${id}&track_id=${active_track}&token=${token}&baseUrl=${window.location.origin}&configuration=${configuration}`}
            onLoad={() => setHideSpinner(true)}
          />
        </Modal>
      ) : null}
    </ElementContainer>
  );
};
const Spinner = () => {
  const spinnerStyle = {
    backgroundColor: "white",
    position: "absolute",
    textAlign: "center",
    top: "45%",
    left: 0,
    width: "100%",
    padding: 10,
    color: "gray",
  };

  const spinnerIcon = {
    fontSize: 72,
  };

  return (
    <div style={spinnerStyle}>
      <FaSpinner style={spinnerIcon} className="bx-loader-circle bx-spin" />
      <p className="m-bottom-0">Loading. Please wait..</p>
    </div>
  );
};
export default UnityGameElement;
