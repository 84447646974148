import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import axios from "axios";
import ElementContainer from "components/shared/ElementContainer";
import Modal from "components/shared/Modal";
import TeacherComment from "components/shared/TeacherComment";
import heic2any from "heic2any";

const PhotoArtifact = (props) => {
  const {
    title,
    titleColor,
    prompt,
    url,
    validFormats,
    token,
    requirement,
    requirable,
    reviewable,
    comment,
    id,
    user,
  } = props;

  const [photo, setPhoto] = useState(props.photo);
  const [completed, setCompleted] = useState(props.completed);
  const [status, setStatus] = useState(props.status);
  const [upload, setUpload] = useState(null);
  const [error, setError] = useState(null);
  const [description, setDescription] = useState("");
  const [display, setDisplay] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isConverting, setIsConverting] = useState(false);

  const endpoint = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

  const ErrorMessage = (props) => {
    const { error } = props;
    if (error) {
      return (
        <div className="text-center danger radius">
          <p>{error}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Final check before uploading
    if (!upload) {
      setError("Please select a file to upload.");
      return;
    }

    setIsUploading(true);
    setError(null);

    let data = new FormData();
    data.set("photo[upload]", upload);
    data.set("photo[description]", description);
    data.set("photo[direct_url]", "");
    data.set("requirement_id", requirement.id);

    endpoint
      .post("/client_api/v1/photos", data)
      .then((r) => {
        const { error } = r.data;
        if (error) {
          setError(error);
        } else {
          const responseData = {
            ...r.data.artifact,
            description: r.data.description,
            upload_filename: upload.name,
            upload_url: r.data.artifact.direct_url,
          };
          setError(null);
          setPhoto(responseData);
          setCompleted(r.data.completed);
          setIsUploading(false);
          setStatus(r.data.status);
          setDisplay(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setError("Upload failed. Please try again.");
        setIsUploading(false);
      });
  };

  const heicPreviewer = async (file) => {
    const preview = await heic2any({
      blob: file,
      toType: "image/png", // "image/jpeg", "image/png" or "image/gif"
      quality: 0.5,
    });

    return preview;
  };

  const fileCheck = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }

    // Extract the file extension (lowercase)
    const fileExtension = file.name.split(".").pop().toLowerCase();

    // Clean and sanitize validFormats
    const allowedFormats = validFormats
      .replace(/\band\b/gi, "") // Remove "and"
      .split(",") // Split on commas
      .map((f) => f.replace(/\./g, "").trim().toLowerCase()) // Remove dots and trim spaces
      .filter(Boolean); // Remove any empty strings

    // HEIC file conversion logic
    if (file.type === "image/heic") {
      setIsConverting(true);
      heic2any({
        blob: file,
        toType: "image/png",
        quality: 0.5,
      })
        .then((result) => {
          const convertedFile = new File(
            [result],
            file.name.replace(/\.heic$/, ".png"),
            {
              type: "image/png",
              lastModified: Date.now(),
            }
          );
          setUpload(convertedFile);
          setIsConverting(false);
          setError(null);
        })
        .catch((e) => {
          console.error("HEIC conversion failed:", e);
          setError(
            "Failed to convert HEIC file. Please try a different format."
          );
          setIsConverting(false);
        });
    } else {
      // If not HEIC, directly set the upload
      setUpload(file);
      setError(null);
    }
  };

  const ModalHeader = (props) => {
    const { title } = props;
    const span = {
      position: "absolute",
      top: 15,
      right: 15,
    };

    const btn = {
      cursor: "pointer",
      color: "lightgray",
    };

    return (
      <div className="modal-header">
        <h2 className="text-center">{title}</h2>
        <span style={span}>
          <a onClick={() => setDisplay(false)} style={btn}>
            <FaTimes className="icon-medium" />
          </a>
        </span>
      </div>
    );
  };

  const UploadButton = (props) => {
    if (isConverting) {
      return (
        <a className="btn btn-secondary radius expand">
          Processing. Please wait...
        </a>
      );
    } else {
      return (
        <a onClick={handleSubmit} className="btn radius expand">
          Upload
        </a>
      );
    }
  };

  return (
    <ElementContainer
      id={id}
      color={titleColor}
      title={title}
      requirable={requirable}
      reviewable={reviewable}
      status={status}
      completed={completed}
      icon={"bxs-file-image"}
      user={user}
      token={token}
    >
      <div className="m-bottom-1">{prompt}</div>

      {photo ? (
        <div>
          <ArtifactUpload
            photo={photo}
            color={titleColor}
            reviewable={reviewable}
            status={status}
            comment={comment}
          />

          <a
            className="btn radius btn-secondary expand marginless"
            onClick={() => setDisplay(true)}
          >
            Click to Re-upload Photo
          </a>
        </div>
      ) : (
        <div>
          <a
            className="btn btn-secondary radius expand marginless drop-shadow"
            onClick={() => setDisplay(true)}
          >
            Click to Upload Photo
          </a>
        </div>
      )}

      <Modal display={display} id={requirement.id}>
        <ModalHeader title={title} />
        <div className="modal-body">
          <form onSubmit={() => setDisplay(true)}>
            <div className="flex f-d-column f-justify-center">
              <QRCode value={url} className="qr-code" />
            </div>

            <h3 className="text-center primary-text">
              Scan QR code with phone OR select file from device
              <br />
              Acceptable Types: {validFormats}
            </h3>

            <label className="text-bold">Select File</label>
            <input type="file" onChange={(e) => fileCheck(e)} />

            <ErrorMessage error={error} />

            <label className="text-bold">Photo Description</label>
            <textarea onChange={(e) => setDescription(e.target.value)} />

            {isUploading && !error ? (
              <div>
                <a
                  onClick={handleSubmit}
                  className="btn btn-secondary radius expand"
                >
                  Uploading. Please wait...
                </a>
              </div>
            ) : (
              <UploadButton />
            )}
          </form>
          <div className="clearfix" />
        </div>
      </Modal>
    </ElementContainer>
  );
};

const ArtifactUpload = (props) => {
  const color = props.color;
  const photo = props.photo;
  const reviewable = props.reviewable;
  const status = props.status;
  const comment = props.comment;

  const uploadUrl = photo?.upload_url;
  const filename = photo?.upload_filename;

  return (
    <div className="f-col-s12 padless">
      <div className="f-col-s12-m6 text-center">
        <a src={uploadUrl} href={uploadUrl}>
          <img className="full-width" src={uploadUrl} alt={filename} />
        </a>
      </div>

      <div className="f-col-s12-m6 flex f-d-column">
        <label className="text-bold">File Name:</label>
        {filename}

        <label className="text-bold">Description:</label>
        {photo.description == "" ? "[Not provided]" : photo.description}

        {reviewable && comment ? (
          <TeacherComment comment={comment} status={status} />
        ) : null}
      </div>
    </div>
  );
};

const NoUpload = () => {
  return <div></div>;
};

PhotoArtifact.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  prompt: PropTypes.string,
  url: PropTypes.string,
  validFormats: PropTypes.string,
  token: PropTypes.string,
  requirable: PropTypes.bool,
  completed: PropTypes.bool,
  photo: PropTypes.object,
  requirement: PropTypes.object,
};

export default PhotoArtifact;
